const theme = {
  name: 'MatchFlix',
  login: {
    typography: {
      useNextVariants: true
    },
    palette: {
      type: 'dark',
      primary: {
        main: '#0079b0',
        light: '#74c3e6',
        contrastText: '#b3b3b3'
      }
    }
  },
  app: {
    typography: {
      useNextVariants: true
    },
    palette: {
      primary: {
        main: '#39a9dc',
        light: '#74c3e6',
        veryLight: '#74c3e6',
        contrastText: '#fff'
      },
      secondary: { main: '#00a69f' }
    },
    overrides: {
      MuiAppBar: {
        colorPrimary: {
          backgroundColor: '#fff'
        }
      },
      MuiButton: {
        label: {
          color: 'white'
        }
      },
      MuiStepIcon: {
        text: {
          fill: 'white',
          color: 'white'
        }
      },
      MuiTooltip: {
        tooltip: {
          'font-size': '14px',
          'max-width': '500px'
        }
      }
    },
    status: {
      danger: 'orange'
    }
  },
  //   logo: '/img/interim_logo_60.png',
  //   loginLogo: '/img/interim_logo_60.png',
  loginBackground: {
    background: '#747e86'
  },
  loginPaper: {
    background: '#fff'
  },
  appBar: {
    background: '#fff',
    zIndex: '1100',

    candidates: {
      background: 'rgba(88,125,175,0.4)'
    },
    companies: {
      background: 'rgba(136,179,175,0.4)'
    },
    projects: {
      background: 'rgba(197,197,184,0.4)'
    }
  }
};

module.exports = theme;
