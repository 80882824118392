import { Grid } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import STEPS from 'shared/components/registrationSteps/index';
import ExternalProfileApp from '../shared/ExternalProfileApp';
import ProfilePage from '../shared/pages/ProfilePage';
import RegistrationPage from '../shared/pages/RegistrationPage';
import './App.css';
import LogoImage from './resources/matchflix_logo.png';
import THEME from './theme';

const theme = createMuiTheme(THEME.app as any);
const brand = 'matchflix';

const App = () => (
  <ExternalProfileApp moduleId="matchflix" theme={theme}>
    <Grid container component="main" justify="center">
      <Switch>
        <Route
          path="/register"
          render={props => <RegistrationPage {...props} steps={STEPS} logoImage={LogoImage} brand={brand} />}
        />
        <Route render={props => <ProfilePage {...props} steps={STEPS} brand={brand} />} />
      </Switch>
    </Grid>
  </ExternalProfileApp>
);

export default App;
